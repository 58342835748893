export const publicUrl = process.env.PUBLIC_URL + '/';
export const staticUrl = process.env.PUBLIC_URL + '/static/';
export const imagesUrl = process.env.PUBLIC_URL + '/static/images/';

export const PlayerState = {
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
  BUFFERING: 3,
  CUED: 5,
};
