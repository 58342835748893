import React, { useState } from 'react';

const DEFAULT_OBSERVER_OPTIONS = {
  rootMargin: '100px',
  threshold: 0.2,
};

const useObserver = (
  { element, onFocus, onBlur, log = false, options = DEFAULT_OBSERVER_OPTIONS },
  dependencies
) => {
  const [isObserved, setObserved] = useState(false);

  React.useEffect(() => {
    if (!element) return;

    const _callback = (entries, observer) => {
      element.dataset.timout = element.dataset.timout ?? null;
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setObserved(true);
          if (log) console.log('intersecting');
          onFocus();
        } else if (!entry.isIntersecting) {
          setObserved(false);
          if (log) console.log('not intersecting');
          if (onBlur) {
            onBlur();
          }
        }
      });
    };

    const observer = new IntersectionObserver(_callback, options);
    observer.observe(element);

    return () => observer.unobserve(element);
    // eslint-disable-next-line
  }, [...dependencies]);

  return isObserved;
};

export default useObserver;
