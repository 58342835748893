import React, { useEffect, useRef, useState } from 'react';

const Container = ({ children, lines = false, ...props }) => {
  const [height, setHeight] = useState(0);
  const container = useRef(null);

  useEffect(() => {
    const wrapper = container.current?.parentElement;
    if (!wrapper) return;
    const resizer = () => {
      setHeight(wrapper.clientHeight);
    };

    resizer();
    window.addEventListener('resize', resizer);

    return () => window.removeEventListener('resize', resizer);
  }, []);

  return (
    <div
      className={`container ${lines ? 'container--lines' : ''}`}
      {...props}
      ref={container}>
      {children && children}
      {lines && (
        <div className="container-line _left" style={{ height }}>
          <LineSvg height={height} />
        </div>
      )}
      {lines && (
        <div className="container-line _right" style={{ height }}>
          <LineSvg height={height} />
        </div>
      )}
    </div>
  );
};

const LineSvg = ({ height }) => (
  <svg
    width="1"
    height={`${height}px`}
    viewBox={`0 0 1 ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <line
      x1="0.5"
      y1="2.18557e-08"
      x2="0.499693"
      y2="7020"
      stroke="currentColor"
      strokeOpacity="0.1"
      strokeDasharray="5 5"
    />
  </svg>
);
export default Container;
