import React from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import useObserver from 'hooks/useObserver';
import { ArrowDownSmall } from 'assets/ICONS';

const HeroNextButton = ({ wrapper }) => {
  const [init, setInit] = React.useState(false);
  const tl = React.useRef();
  const ref = React.useRef();

  useGSAP(() => {
    const button = ref.current;
    const arrow = ref.current.querySelector('svg');

    tl.current = gsap.timeline({ paused: true });

    tl.current
      .fromTo(
        button,
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 0.25, delay: 6 }
      )
      .fromTo(
        // animate arrow
        arrow,
        { y: 5 },
        { y: 0, duration: 0.5, repeat: -1, yoyo: true }
      );
    setInit(true);
  });

  const timlineReset = () => {
    tl.current.restart();
    tl.current.pause();
  };
  useObserver(
    {
      element: wrapper.current,
      onFocus: () => {
        if (tl.current?.play) tl.current.play();
      },
      onBlur: () => {
        if (tl.current?.pause) timlineReset();
      },
    },
    [init]
  );
  return (
    <a
      href="#process"
      className="hero-next button button--text _primary"
      ref={ref}
      onMouseOver={() => tl.current.pause()}>
      Learn more
      {ArrowDownSmall}
    </a>
  );
};

export default HeroNextButton;
