import { imagesUrl } from 'constant';
import useObserver from 'hooks/useObserver';
import React, { useRef, useState } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const HeroBanner = () => {
  const [init, setInit] = useState(false);
  const tl = useRef();
  const banner = useRef();

  useGSAP(
    () => {
      tl.current = gsap.timeline({
        repeat: -1,
        repeatDelay: 0,
        paused: true,
      });

      const icons = document.querySelectorAll('.hero__banner-icon');
      icons.forEach((icon) => {
        tl.current
          .fromTo(
            icon,
            { scale: 0.9, x: '-40%', y: '-45%', opacity: 0 },
            { scale: 1, x: '-50%', y: '-50%', duration: 1, opacity: 1 }
          )
          .fromTo(
            icon.querySelector('span._shadow'),
            { x: 0, y: 0 },
            { x: '0.45em', y: '0.45em', duration: 0.85 },
            '<=0.6'
          )
          .fromTo(
            icon.querySelector('span._glow'),
            { x: 0, y: 0 },
            { x: '-0.15em', y: '-0.15em', duration: 0.85 },
            '<'
          )
          .to(icon, {
            x: '-60%',
            y: '-45%',
            scale: 0.9,
            opacity: 0,
            duration: 0.4,
            delay: 4,
          });
      });
      setInit(true);
    },
    { scope: banner }
  );

  useObserver(
    {
      element: banner.current,
      onFocus: () => {
        if (tl.current?.play) tl.current.play();
      },
      onBlur: () => {
        if (tl.current?.pause) tl.current.pause();
      },
    },
    [init]
  );

  return (
    <div className="hero__inner-banner" ref={banner}>
      <div className="hero__banner">
        <BannerIcon
          wide
          timeline={tl.current}
          src={imagesUrl + 'social/youtube.svg'}
          alt="youtube"
        />
        <BannerIcon
          timeline={tl.current}
          src={imagesUrl + 'social/instagram.svg'}
          alt="instagram"
        />
        <BannerIcon
          timeline={tl.current}
          src={imagesUrl + 'social/tiktok.svg'}
          alt="tiktok"
        />
      </div>
    </div>
  );
};

const BannerIcon = ({ src, alt, wide = false }) => {
  return (
    <div className={`hero__banner-icon ${wide ? '_wide' : ''}`}>
      <span className="_glow"></span>
      <span className="_shadow"></span>
      <img src={src} alt={alt} style={{zIndex:15}}/>
    </div>
  );
};
export default HeroBanner;
