import AnimatedCounter from 'components/AnimatedCounter';
import Button from 'components/Button';
import { PlayerState, imagesUrl } from 'constant';
import React, { useEffect } from 'react';
import { useState } from 'react';
import YouTube from 'react-youtube';

const TestimonialsSlide = ({
  active: visibleSlide,
  avatarURl,
  bannerURl,
  video,
  videoTitle = 'TESTIMONIAL',
  username,
  startDate,
  links,
  profits = {
    revenueBefore: 0,
    revenueAfter: 0,
    subscribersBefore: 0,
    subscribersAfter: 0,
    viewsBefore: 0,
    viewsAfter: 0,
  },
  children,
  players,
  addPlayer,
}) => {
  const [showMobile, setShowMobile] = useState(false);
  const [isVideoActive, setVideoActive] = useState(false);
  const player = players ? players[video] : null;

  const onPlay = () => {
    setVideoActive(true);
    if (player && player.getSize) player.playVideo();
  };
  const onPause = () => {
    setShowMobile(false);
  };
  const onPlayButtonClick = () => {
    setShowMobile(true);
    onPlay();
  };

  useEffect(() => {
    if (!visibleSlide) setShowMobile(false);
  }, [visibleSlide]);

  return (
    <div
      className={`testimonialsSlide ${
        video ? 'testimonialsSlide--video' : 'testimonialsSlide--banner'
      }`}>
      {!video && (
        <div className="testimonialsSlide__banner">
          {bannerURl && <img src={bannerURl} alt={'bg-' + username} />}
        </div>
      )}
      <div className="testimonialsSlide__user">
        <div className="testimonialsSlide__user-img">
          <img src={avatarURl} alt={username} />
          {video && (
            <div className="testimonialsSlide__user-videobtn">
              <Button text={'Watch video'} onClick={onPlayButtonClick} />
            </div>
          )}
        </div>
        <div className="testimonialsSlide__user-info">
          <strong className="h4">{username}</strong>
          <div className="testimonialsSlide__user-info-group">
            <span>Start Date:</span>
            <strong>{startDate}</strong>
          </div>
          <div className="testimonialsSlide__user-info-social">
            {links &&
              Object.keys(links).map((key) => (
                <a key={key} href={links[key]} target="_blank" rel="noreferrer">
                  <img src={imagesUrl + `social/${key}.png`} alt={key} />
                </a>
              ))}
          </div>
        </div>
      </div>
      <div className="testimonialsSlide__content">
        <div className="testimonialsSlide__profits">
          <div className="testimonialsSlide__profits-row">
            <div className="testimonialsSlide__profits-head _placeholder"></div>
            <div className="testimonialsSlide__profits-col">
              <span>Revenue</span>
            </div>
            <div className="testimonialsSlide__profits-col">
              <span>Subscribers</span>
            </div>
            <div className="testimonialsSlide__profits-col">
              <span>
                <span className="hide-mob">Monthly </span>
                Views
                <span className="hide-desk"> / mo</span>
              </span>
            </div>
          </div>
          <div className="testimonialsSlide__profits-row">
            <div className="testimonialsSlide__profits-head">
              <strong>Before</strong>
              <span>Working Together</span>
            </div>
            <div className="testimonialsSlide__profits-col">
              <AnimatedCounter
                className="color-red"
                number={profits.revenueBefore}
                prefix="$"
                suffix=" / mo"
              />
            </div>
            <div className="testimonialsSlide__profits-col">
              <AnimatedCounter
                className="color-red"
                number={profits.subscribersBefore}
              />
            </div>
            <div className="testimonialsSlide__profits-col">
              <AnimatedCounter
                className="color-red"
                number={profits.viewsBefore}
              />
            </div>
          </div>
          <div className="testimonialsSlide__profits-row">
            <div className="testimonialsSlide__profits-head">
              <strong>After</strong>
              <span>Working Together</span>
            </div>
            <div className="testimonialsSlide__profits-col">
              <AnimatedCounter
                className="color-green-1"
                number={profits.revenueAfter}
                prefix="$"
                suffix="+ / mo"
              />
            </div>
            <div className="testimonialsSlide__profits-col">
              <AnimatedCounter
                className="color-green-1"
                number={profits.subscribersAfter}
                suffix="+"
              />
            </div>
            <div className="testimonialsSlide__profits-col">
              <AnimatedCounter
                className="color-green-1"
                number={profits.viewsAfter}
                suffix="+"
              />
            </div>
          </div>
        </div>
        <div className="testimonialsSlide__content-text">
          <p>{children}</p>
        </div>
      </div>
      {video && (
        <VideoPlayer
          addClass={showMobile ? 'show-mobile' : ''}
          video={video}
          videoTitle={videoTitle}
          players={players}
          addPlayer={addPlayer}
          onPlay={onPlay}
          onPause={onPause}
          isActive={isVideoActive}
          setActive={setVideoActive}
        />
      )}
    </div>
  );
};
const VideoPlayer = ({
  video,
  videoTitle,
  addClass,
  isActive,
  setActive,
  addPlayer,
  onPlay,
  onPause,
}) => {
  const [isReady, setReady] = useState(false);
  const [isPlaying, setPlaying] = useState(false);

  const getClass = () => {
    let className = 'testimonialsSlide__video';
    className += addClass ? ` ${addClass}` : '';
    className += isActive ? ' active' : '';
    className += isPlaying ? ' playing' : '';
    return className;
  };
  const onReady = ({ target }) => {
    setReady(true);
    addPlayer(target);
    target.playVideo();
  };
  const updateState = (event) => {
    let isPlaying =
      event.data === PlayerState.PLAYING ||
      event.data === PlayerState.BUFFERING;
    setPlaying(isPlaying);
    if (event.data === PlayerState.PAUSED || event.data === PlayerState.ENDED) {
      setActive(false);
      onPause();
    }
  };

  return (
    <div className={getClass()}>
      <button
        className="testimonialsSlide__video-play"
        onClick={onPlay}></button>
      <div className="testimonialsSlide__video-poster">
        <img
          src={`http://img.youtube.com/vi/${video}/sddefault.jpg`}
          alt={videoTitle}
        />
        <div className="testimonialsSlide__video-poster-title">
          {videoTitle}
        </div>
      </div>
      {(isActive || isReady) && (
        <YouTube
          videoId={video}
          className={'testimonialsSlide__video-frame'}
          onReady={onReady}
          onStateChange={updateState} // defaults -> noop
          opts={{
            playerVars: { loop: 1 },
          }}
        />
      )}
    </div>
  );
};
export default TestimonialsSlide;
