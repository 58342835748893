import React, { useRef, useState } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const numberWithCommas = (n) => {
  var parts = parseInt(n).toString().split('.');
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const AnimatedCounter = ({
  prefix = '',
  suffix = '',
  paused = true,
  number,
  className = '',
  ...props
}) => {
  const [value, setValue] = useState(0);
  const ref = useRef();
  const wrapperRef = useRef();

  const setDefValue = () => {
    let num = parseInt(number.toString().replace(',', ''));
    if (isNaN(num)) setValue(number);
    else setValue(numberWithCommas(num));
  };
  useGSAP(
    () => {
      const text = ref.current;
      if (!text) return;
      const tl = gsap.timeline({ paused });
      const obj = { value: 0 };
      let num = parseInt(number.toString().replace(',', ''));
      wrapperRef.current.tl = tl;

      if (isNaN(num)) {
        setDefValue();
        return;
      }

      tl.to(
        obj,
        {
          duration: 2.5,
          ease: 'none',
          value: num,
          onUpdate: () => {
            text.innerHTML = numberWithCommas(obj.value);
          },
          onComplete: () => {
            wrapperRef.current.classList.add('animated');
            setDefValue();
          },
        },
        '<'
      );
    },
    { scope: ref }
  );

  return (
    <strong
      className={`animated-count ${className}`}
      ref={wrapperRef}
      {...props}>
      {isNaN(parseInt(value)) ? (
        value
      ) : (
        <>
          {prefix}
          <em ref={ref}>{0}</em>
          {suffix}
        </>
      )}
    </strong>
  );
};

export default AnimatedCounter;
