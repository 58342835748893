import React, { useId, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Container from 'components/layouts/Container';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import useObserver from 'hooks/useObserver';
import AnimatedText from 'components/AnimatedText';

const Contacts = () => {
  const [init, setInit] = useState(false);
  const tl = useRef();
  const wrapperRef = useRef();

  useGSAP(() => {
    const wrapper = wrapperRef.current;
    const title = wrapper.querySelector('h2');
    const subtitle = wrapper.querySelector('h5');
    const button = wrapper.querySelector('.contacts__button');
    const buttonText = wrapper.querySelector('.contacts__button-arrow span');
    const arrow = wrapper.querySelector('.contacts__button-arrow');
    const arrowMaskPath = wrapper.querySelector(
      '.contacts__button-arrow mask path'
    );
    const arrowPath = wrapper.querySelector('.contacts__button-arrow g path');
    const pathOffset = arrowMaskPath.getTotalLength();
    gsap.set(arrowMaskPath, {
      strokeDashoffset: pathOffset,
      strokeDasharray: `${pathOffset} ${pathOffset}`,
    });

    tl.current = gsap.timeline({ paused: true });

    tl.current
      .fromTo(
        subtitle,
        { opacity: 0, y: 15 },
        { opacity: 1, y: 0, duration: 0.6, delay: 0.2 }
      )
      .fromTo(
        title,
        { opacity: 0, y: 25 },
        { opacity: 1, y: 0, duration: 0.6 },
        '>-0.25'
      )
      .fromTo(
        button,
        { opacity: 0, y: 25 },
        { opacity: 1, y: 0, duration: 0.8 },
        '>-0.2'
      )
      // .fromTo(
      //   buttonText,
      //   { opacity: 0 },
      //   { opacity: 1, duration: 0.4 },
      //   '>-0.3'
      // )

      .fromTo(arrow, { opacity: 0 }, { opacity: 1, duration: 0.5 }, '>-0.2')
      .fromTo(
        arrowMaskPath,
        { strokeDashoffset: pathOffset },
        {
          strokeDashoffset: 0,
          duration: 0.7,
          // repeat: -1,
          repeatDelay: 1,
        },
        '>-0.2'
      )
      .add(buttonText.tl.play(), '<=0.5')
      .fromTo(
        arrowPath,
        { strokeDashoffset: 200 },
        {
          strokeDashoffset: 0,
          duration: 8,
          ease: 'none',
          repeat: -1,
          repeatDelay: 0,
        },
        '<'
      );

    setInit(true);
  });
  useObserver(
    {
      element: wrapperRef.current,
      onFocus: () => tl.current.play(),
      onBlur: () => {
        tl.current.restart(true);
        tl.current.pause();
      },
      options: {},
    },
    [init]
  );

  return (
    <section className="contacts">
      <Container lines>
        <div className="contacts__inner" ref={wrapperRef}>
          <div className="contacts__title">
            <h5>Ready to do this?</h5>
            <h2>Let’s get started!</h2>
          </div>
          <div className="contacts__button">
            <NavLink to={'/getstarted'} className="button button--primary">Get started</NavLink>
            <div className="contacts__button-arrow">
              <Arrow />
              <AnimatedText text="quick, free, easy :)" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const Arrow = () => {
  const id = useId();

  return (
    <svg
      width="81"
      height="61"
      viewBox="0 0 81 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          id={`paint-${id}`}
          x1="12"
          y1="52"
          x2="94.5"
          y2="7"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4360F6" />
          <stop offset="1" stopColor="#FCFCFF" />
        </linearGradient>
        <mask id={`mask-${id}`} maskUnits="userSpaceOnUse">
          <path
            stroke="#fff"
            strokeWidth="4"
            d="M12 52C28.5 46 65.2 27.4 80 1"
          />
        </mask>
      </defs>
      <g mask={`url(#mask-${id})`}>
        <path
          d="M12 52C28.5 46 65.2 27.4 80 1"
          stroke={`url(#paint-${id})`}
          strokeWidth="2"
          strokeDasharray="5 5"
        />
      </g>
      <path
        d="M15.1414 59.4044L1.99779 54.3303L13.4229 43.9999"
        stroke="#4360F6"
        strokeWidth="2"
      />
    </svg>
  );
};
export default Contacts;
