import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import React, { useRef } from 'react';

const AnimatedText = ({ text, paused = true }) => {
  const wrapper = useRef();

  useGSAP(
    () => {
      // create animation timeline
      const tl = gsap.timeline({ paused });
      tl.fromTo(
        'em',
        { scale: 0.5, y: 3, opacity: 0 },
        { scale: 1, opacity: 1, y: 0, duration: 0.2, stagger: 0.05 }
      );
      wrapper.current.tl = tl;
    },
    { scope: wrapper }
  );

  return (
    <span className="animated-text" ref={wrapper}>
      {Array.from(text).map((char, index) => (
        <em key={index}>{char === ' ' ? <>&nbsp;</> : char}</em>
      ))}
    </span>
  );
};

export default AnimatedText;
