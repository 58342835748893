import React from 'react';
import Container from './Container';
import { staticUrl } from 'constant';
import { Copyright, LinkedIn } from 'assets/ICONS';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="footer__inner">
          <div className="footer__inner-logo">
            <div className="logo">
              <img src={staticUrl + '/logo-footer.svg'} alt="CreatorWise" />
            </div>
          </div>
          <div className="footer__inner-copyright">
            <span className='hide-mob'>Copyright</span>
            {Copyright}
            <span>2024 CREATOR WISE, LLC. All Rights Reserved.</span>
          </div>
          <div className="footer__inner-links">
            <a href="https://www.linkedin.com/company/creatorwise/">
              {LinkedIn}
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
