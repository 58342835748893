import React, { useId } from 'react';

const ProcessItem = ({ title, lastItem, children }) => {
  const line = React.useRef();

  return (
    <li className="process__step">
      <div className="process__step-title">
        <span className="h6">{title}</span>
      </div>
      <div className="process__step-text">
        <p>{children}</p>
      </div>
      <div className="process__step-line">
        <ProcessLine ref={line} />
      </div>
    </li>
  );
};
const ProcessLine = React.forwardRef((props, ref) => {
  const maskId = 'mask-' + useId();

  return (
    <svg
      width="74"
      height="150"
      viewBox="0 0 74 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id={maskId} maskUnits="userSpaceOnUse">
          <path
            d="M72.5 0.5L72.5 72L0.999994 72L0.999994 149.5"
            stroke="#fff"
            strokeWidth="4"
            strokeDashoffset="0"
            strokeDasharray="256 256"
            fill="none"
            className="mask-path"
          />
        </mask>
      </defs>
      <g mask={`url(#${maskId})`}>
        <path
          d="M72.5 0.5L72.5 72L0.999994 72L0.999994 149.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeDasharray={'5 5'}
          fill="none"
          ref={ref}
        />
      </g>
    </svg>
  );
});

export default ProcessItem;
