import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './views/home';
import About from 'views/about';
import Contact from 'views/contact';

const App = () => {
  return (
    <Routes>
      <Route path="/getstarted" index element={<Contact />} />
      <Route path="/about" index element={<About />} />
      <Route path="/" index element={<Home />} />
      <Route path="*" element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default App;
