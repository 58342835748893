import React, { useRef, useState } from 'react';
import { ArrowLeft, ArrowRight } from 'assets/ICONS';
import Container from 'components/layouts/Container';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import TestimonialsSlide from './TestimonialsSlide';
import { imagesUrl } from 'constant';
import { links, profits } from './constants';
import useObserver from 'hooks/useObserver';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const Testimonials = () => {
  const [init, setInit] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [players, setPlayers] = useState({});

  const wrapperRef = useRef();
  const tl = useRef([]);

  // const player
  const addPlayer = (player) => {
    let vidoe_id = player?.getVideoData()?.video_id;
    setPlayers({ ...players, [vidoe_id]: player });
  };
  const stopPlayers = () => {
    Object.values(players).forEach((player) => {
      player.stopVideo();
    });
  };
  const wrapper = wrapperRef.current;

  useGSAP(() => setInit(true));

  const bindCounters = (timeline, query = null) => {
    if (!wrapper?.querySelectorAll) return;

    wrapper
      .querySelectorAll(
        query ?? '.swiper-slide-active .animated-count:not(.animated)'
      )
      .forEach((counter) => {
        if (counter?.tl?.play) {
          timeline.add(counter.tl.play(), '<');
        }
      });
  };
  const isObserved = useObserver(
    {
      // log: true,
      element: wrapper,
      onFocus: () => {
        if (!wrapper?.querySelectorAll) return;
        const timeline = gsap.timeline({ paused: true });
        timeline.fromTo(
          wrapper.querySelectorAll('h2, h5'),
          { opacity: 0, y: -10 },
          { opacity: 1, y: 0, duration: 0.6, stagger: 0.2 }
        );
        bindCounters(timeline);
        timeline.play();
        tl.current.push(timeline);
      },
      onBlur: () => {
        stopPlayers();
        if (tl.current?.filter) {
          tl.current.forEach((timeline) => {
            timeline.kill();
          });
          tl.current = [];
        }
      },
    },
    [init, players]
  );

  const animateCounters = (index = null) => {
    if (!isObserved) return;
    const timeline = gsap.timeline({ paused: true });
    const query = index
      ? `[data-swiper-slide-index="${index}"] .animated-count:not(.animated)`
      : null;
    bindCounters(timeline, query);
    timeline.play();
    window.timeline = timeline;

    tl.current.push(timeline);
  };

  return (
    <section className="testimonials" id="testimonials" ref={wrapperRef}>
      <Container lines>
        <div className="testimonials__inner">
          <div className="testimonials__title">
            <h5>Cold, hard, results.</h5>
            <h2>Client Testimonials</h2>
          </div>
          <div className="testimonials__inner-slider">
            <Swiper
              loop
              modules={[Navigation]}
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={(sw) => {
                animateCounters(sw.activeIndex);
                setActiveIndex(sw.activeIndex);
                stopPlayers();
              }}
              // onSwiper={(sw) => (swiper.current = sw)}
              navigation={{
                prevEl: '.swiper-arrow--left',
                nextEl: '.swiper-arrow--right',
              }}>
              <SwiperSlide>
                <TestimonialsSlide
                  active={activeIndex === 0}
                  avatarURl={imagesUrl + 'testimonials/dmac.png'}
                  username={'@dmac'}
                  startDate={'September 11, 2023'}
                  profits={profits.dmac}
                  video={'7UtC5vE5ME0'}
                  addPlayer={addPlayer}
                  players={players}
                  links={links.dmac}>
                  Daniel was killing it on short form content but was making
                  below $1,000/mo on average. He wanted to grow his brand beyond
                  just short form videos, so we helped transition him to making
                  long form video content as well. Safe to say this was a great
                  decision. In 3 months, we helped Daniel amass 1-Million views
                  on his long form content, more than doubled his monthly ad
                  revenue (not even working with sponsors yet!), and reached
                  100k+ subscribers after starting at 60k 3 months ago.
                </TestimonialsSlide>
              </SwiperSlide>
              <SwiperSlide>
                <TestimonialsSlide
                  active={activeIndex === 1}
                  avatarURl={imagesUrl + 'testimonials/baxate.png'}
                  username={'@baxate'}
                  startDate={'October 20, 2023'}
                  profits={profits.baxate}
                  video={'ZbARrrvbnYs'}
                  addPlayer={addPlayer}
                  players={players}
                  links={links.baxate}>
                  When we first began, Baxate was a man of great influence on
                  TikTok/IG and he wanted to take that influence to YouTube,
                  knowing that’s where he’d be able to grow the most committed
                  audience over time. In less than two months we helped him get
                  his channel monetized, gain 3k+ new subscribers, and get 100k+
                  views. We’ve helped professionally produce his podcast for
                  release on YouTube and Spotify and created engaging clips that
                  have amassed 1M+ views in short form media.
                </TestimonialsSlide>
              </SwiperSlide>
              <SwiperSlide>
                <TestimonialsSlide
                  active={activeIndex === 2}
                  avatarURl={imagesUrl + 'testimonials/sajjaadkhader.png'}
                  username={'@sajjaadkhader'}
                  bannerURl={
                    imagesUrl + 'testimonials/sajjaadkhader-banner.webp'
                  }
                  startDate={'January 30, 2023'}
                  profits={profits.sajjaadkhader}
                  links={links.sajjaadkhader}>
                  Sajjaad is one of the leading voices in the software
                  engineering community on platforms such as TikTok/IG. But like
                  many others, he knows the real value of growing an audience on
                  YouTube (and so our work together commenced!). We have helped
                  Sajjaad set up a consistent content schedule, strategize on
                  content ideas, continue to grow his audience through longer
                  form content on YouTube, and many other things. He’s currently
                  building the channel to educate aspiring tech youth, and drive
                  traffic to his business that he plans to expand in the near
                  future.
                </TestimonialsSlide>
              </SwiperSlide>
              <SwiperSlide>
                <TestimonialsSlide
                  active={activeIndex === 3}
                  avatarURl={imagesUrl + 'testimonials/gavinherman.png'}
                  bannerURl={imagesUrl + 'testimonials/gavinherman-banner.webp'}
                  username={'@gavinherman'}
                  startDate={'July 11, 2023'}
                  profits={profits.gavinherman}
                  links={links.gavinherman}>
                  I’m Gavin, hey! In late July of 2023 I decided to start making
                  videos again. Though I have only uploaded one video roughly
                  every 4 weeks, I amassed 2k+ subscribers in less then 7
                  months, reached 350k+ views, and got my channel monetized in
                  under 5 months. I’ve only made $1,000 directly from the
                  channel so far, but I’ve yet to accept any sponsorships, or
                  sell any digital products. Though my channel is not directly
                  related to my business, I have signed 2 clients from the
                  channel.
                </TestimonialsSlide>
              </SwiperSlide>
            </Swiper>
            <div className="testimonials__inner-slider-arrows">
              <div className="swiper-arrow swiper-arrow--left">{ArrowLeft}</div>
              <div className="swiper-arrow swiper-arrow--right">
                {ArrowRight}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Testimonials;
