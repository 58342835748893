import { ArrowRightSmall } from 'assets/ICONS';
import { staticUrl } from 'constant';
import React, { useState } from 'react';
import { Link,NavLink, useLocation } from 'react-router-dom';
import Container from './Container';
import useMedia from 'hooks/useMedia';
// import Button from 'components/Button';

const Header = () => {
  const [menu, setMenu] = useState(false);
  const isMobile = useMedia(768);
  const location = useLocation();

  React.useEffect(() => {
    close();
  }, [location]);

  const open = () => {
    document.body.classList.add('active');
    setMenu(true);
  };
  const close = () => {
    document.body.classList.remove('active');
    setMenu(false);
  };
  const toggle = () => {
    if (menu) close();
    else open();
  };
  return (
    <>
      <header className="header">
        <Container>
          <div className="header__inner">
            <Link to="/" className="logo">
              <img src={staticUrl + 'logo.svg'} alt="CreatorWise" />
            </Link>
            {!isMobile && (
              <nav className="header__inner-nav">
                <Link
                  to={'/about'}
                  className="button button--text"
                  children="About"
                />
                {/* <Link
                  to={'/getstarted'}
                  className="button button--text"
                  children="Get started"
                /> */}
                <NavLink to="/getstarted" className="button button--primary">
                  Get Started {ArrowRightSmall}
                </NavLink>
              </nav>
            )}
            {isMobile && <Burger active={menu} onClick={toggle} />}
          </div>
        </Container>
      </header>
      {isMobile && <Menu active={menu} close={close} />}
    </>
  );
};

const Menu = ({ active = true, close }) => {
  const onWrapperClick = (e) => {
    if (e.target === e.currentTarget) close();
  };
  return (
    <div
      className={`menu__wrapper ${active ? 'active' : ''}`}
      onClick={onWrapperClick}>
      <nav className={`menu ${active ? 'active' : ''}`}>
        <Link to="/" className="logo">
          <img src={staticUrl + 'logo.svg'} alt="CreatorWise" />
        </Link>
        <div className="menu__links">
          <Link to="/" className="menu-link">
            Home
          </Link>
          <a href="/#testimonials" className="menu-link">
            Testimonials
          </a>
          <Link to="/about" className="menu-link">
            About
          </Link>
        </div>
        <NavLink to="/getstarted" className="button button--primary">
                  Get Started {ArrowRightSmall}
                </NavLink>
      </nav>
    </div>
  );
};
const Burger = ({ active, ...props }) => (
  <button className={`burger ${active ? 'active' : ''}`} {...props}>
    <span></span>
  </button>
);

export default Header;
