import React, { useRef } from 'react';
// import Button from 'components/Button';
import { NavLink } from 'react-router-dom';
import Container from 'components/layouts/Container';
import Footer from 'components/layouts/Footer';
import Header from 'components/layouts/Header';
import { ArrowRightSmall } from 'assets/ICONS';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const About = () => {
  return (
    <>
      <Header />
      <AboutInner />
      <Footer />
    </>
  );
};

const AboutInner = () => {
  const wrapperRef = useRef();

  useGSAP(
    () => {
      const tl = gsap.timeline({ paused: true });

      tl.fromTo(
        'h2, h5, p, strong, .button',
        { opacity: 0, y: -10 },
        { opacity: 1, y: 0, duration: 0.6, stagger: 0.3 }
      );
      tl.play();

      // setInit(true);
    },
    { scope: wrapperRef }
  );

  return (
    <section className="about" ref={wrapperRef}>
      <div className="bg"></div>
      <Container lines>
        <div className="about__inner">
          <div className="about__title">
            <h5>ABOUT</h5>
            <h2>
              THE CREATOR WISE MISSION
            </h2>
          </div>
          <div className="about__inner-content h5">
            <p>
              <strong>THE MISSION:</strong>
              We want to help creators and aspiring creators become the best
              possible creators they can be, while knowing that they can always
              improve somewhere.
            </p>
            <p>
              <strong>THE WHY:</strong>
              Long gone is the day where you could upload videos to YouTube on a
              whim or randomly post on Instagram and go viral, let alone build
              an audience. Competition is simply too tough, and as barriers to
              entry continue to drop, more people with similar goals to you will
              enter the space. 
              <br />
              <br />
              This is why you need to be a Wise Creator.
            </p>
            <p>
              <strong> WHAT THAT MEANS:</strong>
              To put it simply, being a Wise Creator means you are learning deep
              and wide, while constantly applying those learnings and rapidly
              iterating. It also means you get help when you need it.
            </p>
            <h5>
              “The only true wisdom is in knowing you know nothing.” <br />
              <i> ~ Socrates</i>
            </h5>
            <p>
              This is the only reliable way you give yourself a chance at
              building a loyal and trusting audience and making “full-time
              content creator” your full-time gig. 
              <br />
              <br />
            </p>
          </div>
          <h5 style={{textAlign:"center"}}>It’s easier than you think to get started, and it’s fun!</h5>
          <div className="about__inner-actions">
          <NavLink to="/getstarted" className="button button--primary" style={{padding:"12px 16px"}}>
                  Get Started {ArrowRightSmall}
                </NavLink>
            {/* <Button>Get Started {ArrowRightSmall}</Button> */}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default About;
