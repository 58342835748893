import Container from 'components/layouts/Container';
import React, { useRef, useState } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
// import { TabNav, TabNavButton } from 'components/TabNav';
import useObserver from 'hooks/useObserver';
import ProjectsVideo from './ProjectsVideo';

const Projects = () => {
  const [init, setInit] = useState(false);
  const [projectFilter,setProjectFilter]=useState("long");
  const players = useRef({});
  const wrapperRef = useRef();
  const tl = useRef();

  const stopAll = (except) => {
    let except_id = except?.getVideoData()?.video_id;

    Object.values(players.current).forEach((player) => {
      let video_id = player?.getVideoData()?.video_id;
      if (video_id !== except_id && player?.stopVideo) player.stopVideo();
    });
  };

  const addPlayer = (player) => {
    let vidoe_id = player?.getVideoData()?.video_id;
    players.current[vidoe_id] = player;
  };
  const onPlay = (target) => {
    stopAll(target);
  };

  useGSAP(
    () => {
      tl.current = gsap.timeline({ paused: true });

      tl.current
        .fromTo(
          'h2, h5, p, .tabnav',
          { opacity: 0, y: -10 },
          { opacity: 1, y: 0, duration: 0.6, stagger: 0.2 }
        )
        .fromTo(
          '.projects__grid-item',
          { opacity: 0, y: 10, scale: 1.1 },
          { opacity: 1, y: 0, scale: 1, duration: 0.25, stagger: 0.1 },
          '<=0.35'
        );

      setInit(true);
    },
    { scope: wrapperRef }
  );
  useObserver(
    {
      element: wrapperRef.current,
      onFocus: () => {
        tl.current.play();
      },
      onBlur: () => {
        tl.current.restart(true).pause();
        stopAll();
      },
      options: {},
    },
    [init]
  );

  return (
    <section className="projects" ref={wrapperRef}>
      <Container>
        <div className="projects__inner">
          <div className="projects__title">
            <h5>Just some of the</h5>
            <h2>Projects we’ve worked on</h2>
          </div>
          {/* <div className="projects__inner-tabnav">
            <TabNav>
              <TabNavButton onClick={(e) => setProjectFilter('long')} active={projectFilter==='long'}>Long form</TabNavButton>
              <TabNavButton onClick={(e) => setProjectFilter('short')}active={projectFilter==='short'}>Short form</TabNavButton>
            </TabNav>
          </div> */}
          <div className="projects__grid">
            {projectFilter==="long" && (
           <> <ProjectsVideo
            video={'rohsBbXHfEA'}
            addPlayer={addPlayer}
            onPlay={onPlay}
            time="662"
          />
          <ProjectsVideo
            video={'i26Az3c34n0'}
            addPlayer={addPlayer}
            onPlay={onPlay}
            time="894"
          />
          <ProjectsVideo
            video={'BQUPv6Q_Ua8'}
            addPlayer={addPlayer}
            onPlay={onPlay}
            time="28"
          />
          <ProjectsVideo
            video={'oUlpbue-Gw0'}
            addPlayer={addPlayer}
            onPlay={onPlay}
          />
          <ProjectsVideo
            video={'nak-UbbpxnU'}
            addPlayer={addPlayer}
            onPlay={onPlay}
            time="789"
          />
          <ProjectsVideo
            video={'d19er0aRU7A'}
            addPlayer={addPlayer}
            onPlay={onPlay}
            time="1116"
          />
          <ProjectsVideo
            video={'MD5-HByRxoA'}
            addPlayer={addPlayer}
            onPlay={onPlay}
          />
          <ProjectsVideo
            video={'Nd9rdmDtZ3A'}
            addPlayer={addPlayer}
            onPlay={onPlay}
          />
          <ProjectsVideo
            video={'kntcUApUBoM'}
            addPlayer={addPlayer}
            onPlay={onPlay}
          />
          </>
            )}

          {projectFilter==="short" && (
           <> <ProjectsVideo
            video={'fVx8n7LltT0'}
            addPlayer={addPlayer}
            onPlay={onPlay}
            time="662"
          />
          <ProjectsVideo
            video={'JAZrWDc-PmM'}
            addPlayer={addPlayer}
            onPlay={onPlay}
            time="894"
          />
          <ProjectsVideo
            video={'YNT1akOS1ZM'}
            addPlayer={addPlayer}
            onPlay={onPlay}
            time="28"
          />
          <ProjectsVideo
            video={'N1znE8eKy-c'}
            addPlayer={addPlayer}
            onPlay={onPlay}
          />
          <ProjectsVideo
            video={'E52LjwcuAqc'}
            addPlayer={addPlayer}
            onPlay={onPlay}
            time="789"
          />
          <ProjectsVideo
            video={'2NyRPgEPpoM'}
            addPlayer={addPlayer}
            onPlay={onPlay}
            time="1116"
          />
          <ProjectsVideo
            video={'Kv6d_CLZRm8'}
            addPlayer={addPlayer}
            onPlay={onPlay}
          />
          <ProjectsVideo
            video={'Kv6d_CLZRm8'}
            addPlayer={addPlayer}
            onPlay={onPlay}
          />
          <ProjectsVideo
            video={'Kv6d_CLZRm8'}
            addPlayer={addPlayer}
            onPlay={onPlay}
          />
          </>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Projects;
