import gsap from 'gsap';
import React from 'react';

const useMedia = (breakpoint = 1024) => {
  const [isSmaller, setIsSmaller] = React.useState(false);

  React.useEffect(() => {
    let mm = gsap.matchMedia();
    mm.add(`(min-width: ${breakpoint}px)`, () => setIsSmaller(false));
    mm.add(`(max-width: ${breakpoint + 1}px)`, () => setIsSmaller(true));
  }, [breakpoint]);
  
  return isSmaller;
};

export default useMedia;
