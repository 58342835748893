export const links = {
  dmac: {
    tiktok: 'https://www.tiktok.com/@dmac_football?lang=en',
    instagram: 'https://www.instagram.com/dmac_ig/',
    linkedin:
      'https://au.linkedin.com/in/daniel-mcbriarty-8970a315a?challengeId=AQGyg7DymydvcQAAAY9Z_c9ZECjTVQvQlNVaWAu9e3qgbmlEw6QWKwDQGLrYzqNrCHwhS2nDUdFYAg-pLUcnIpum4cUSNa1Jvw&submissionId=7b113afb-e89e-cd17-9ddf-5207a06a6815&challengeSource=AgGpveLQNyyTcgAAAY9Z_fxH_m6APFyUYdYJvCeMbAt-4u64UDUs4BxzE_UeU4Q&challegeType=AgHQ6XoQ6nE9NgAAAY9Z_fxK_A739s-HCyfg_gdPCv5p4MGxjL9GvSA&memberId=AgH6nkLVjr52fgAAAY9Z_fxOaFZ2CWb4DFTMrpSNZh0LDJ8&recognizeDevice=AgHz4zvj-xtxJwAAAY9Z_fxRYIZMNJJE62-5nbXzXabTHR8Sc_w3',
    youtube: 'https://www.youtube.com/@DMac__',
  },
  baxate: {
    tiktok: 'https://www.tiktok.com/@baxate_carter?lang=en',
    instagram: 'https://www.instagram.com/baxate_carter/?hl=en',
    linkedin:
      'https://www.linkedin.com/in/carter-abdallah-958666140?trk=public_post_feed-actor-name',
    youtube: 'https://www.youtube.com/@Baxate',
  },
  sajjaadkhader: {
    tiktok: 'https://www.tiktok.com/@sajjaadkhader?lang=en',
    instagram: 'https://www.instagram.com/sajjaad.khader/?hl=en',
    linkedin: 'https://www.linkedin.com/in/sajjaad-khader',
    youtube: 'https://www.youtube.com/@SajjaadKhader',
  },
  gavinherman: {
    linkedin: 'https://www.linkedin.com/in/gavin-herman/',
    youtube: 'https://www.youtube.com/@GavinHerman',
  },
};
export const profits = {
  dmac: {
    revenueBefore: 750,
    revenueAfter: 2500,
    subscribersBefore: 60000,
    subscribersAfter: 166000,
    viewsBefore: 9000000,
    viewsAfter: 15000000,
  },
  baxate: {
    revenueBefore: 'N/A',
    revenueAfter: '200',
    subscribersBefore: '3,500',
    subscribersAfter: 12000,
    viewsBefore: 2500,
    viewsAfter: 15000,
  },
  sajjaadkhader: {
    revenueBefore: 'N/A',
    revenueAfter: '500',
    subscribersBefore: '5,000',
    subscribersAfter: 10000,
    viewsBefore: 3000,
    viewsAfter: 20000,
  },
  gavinherman: {
    revenueBefore: 'N/A',
    revenueAfter: '500',
    subscribersBefore: '250',
    subscribersAfter: 3500,
    viewsBefore: 500,
    viewsAfter: 45000,
  },
};
