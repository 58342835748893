import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';

const TextMark = ({ texts, paused = false, timeline: parentTimline }) => {
  const wrapperRef = useRef();

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const spans = wrapper.querySelectorAll('span');

    let width;
    const calcWidth = () => {
      let width = 0;
      spans.forEach((element, index) => {
        width = width > element.clientWidth ? width : element.clientWidth;
      });
      return width;
    };
    width = calcWidth();
    gsap.set(wrapper, { width });

    const timeline = gsap.timeline({
      paused,
      repeat: -1,
      repeatDelay: 0,
      onUpdate: () => {
        if (width !== calcWidth()) {
          gsap.set(wrapper, { width: calcWidth() });
        }
      },
    });
    spans.forEach((element, index) => {
      gsap.set(element, { position: 'absolute', opacity: 1 });

      timeline
        .fromTo(element, { opacity: 0, y: '30%' }, { opacity: 1, y: '0%' })
        .to(element, { opacity: 0, y: '-30%', delay: 1.5 });
    });
    if (parentTimline) parentTimline.add(timeline.play(), 0);
    wrapper.tl = timeline;
  });

  return (
    <h1 className="text-mark" ref={wrapperRef}>
      {texts.map((text, id) => (
        <span key={id}>{text}</span>
      ))}
    </h1>
  );
};

export default TextMark;
