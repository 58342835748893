import { useGSAP } from '@gsap/react';
import { Accordion, AccordionItem } from 'components/Accordion';
import Container from 'components/layouts/Container';
import gsap from 'gsap';
import useObserver from 'hooks/useObserver';
import React, { useRef, useState } from 'react';

const FAQ = () => {
  const [active, setActive] = useState(null);
  const toggleActive = (id) => {
    if (active === id) setActive(null);
    else setActive(id);
  };

  const [init, setInit] = useState(false);
  const wrapperRef = useRef();
  const tl = useRef();

  useGSAP(
    () => {
      tl.current = gsap.timeline({ paused: true });

      tl.current
        .fromTo(
          'h2, h5',
          { opacity: 0, y: -10 },
          { opacity: 1, y: 0, duration: 0.6, stagger: 0.2 }
        )
        .fromTo(
          '.accordion__item ',
          { opacity: 0, y: 10, scale: 0.98 },
          { opacity: 1, y: 0, scale: 1, duration: 0.25, stagger: 0.2 },
          '<=0.35'
        );

      setInit(true);
    },
    { scope: wrapperRef }
  );
  useObserver(
    {
      element: wrapperRef.current,
      onFocus: () => {
        tl.current.play();
      },
      onBlur: () => {
        tl.current.restart(true).pause();
      },
      options: {},
    },
    [init]
  );

  return (
    <section className="faq" ref={wrapperRef}>
      <Container>
        <div className="faq__inner">
          <div className="faq__title">
            <h5>Still unsure?</h5>
            <h2>FAQS</h2>
          </div>
          <div className="faq__inner-accordion">
            <Accordion>
              <AccordionItem
                active={active === `item-1`}
                onClick={() => toggleActive(`item-1`)}
                title={'What makes Creatorwise unique?'}>
                <p>
                  Views, subscribers, and likes are all important, but you can't
                  deposit these into your bank account. We focus on building and
                  scaling your own evergreen digital asset that will generate
                  you more income through increased leads, product sales, etc.
                </p>
              </AccordionItem>
              <AccordionItem
                active={active === `item-2`}
                onClick={() => toggleActive(`item-2`)}
                title={'When will I see results?'}>
                <p>
                  It depends. Admittedly, while we do everything we possibly can
                  to help, results are never guaranteed. Some of our clients
                  have seen revenue double within 2 months. Others have gone a
                  few months without seeing substantial revenue returns. Many
                  give up before they have a chance to sell. Building a brand
                  online is an arduous process that takes an entire team to make
                  happen, but the returns are exponential.
                </p>
              </AccordionItem>
              <AccordionItem
                active={active === `item-3`}
                onClick={() => toggleActive(`item-3`)}
                title={'How much does this cost?'}>
                <p>
                  Our packages begin at $1,000/mo. Your needs are unique, as
                  such we will provide you with a quote during or after our
                  first call. The first call is intended to help us better
                  understand your business and what services we can provide to
                  build and scale your brand.
                </p>
              </AccordionItem>
              <AccordionItem
                active={active === `item-4`}
                onClick={() => toggleActive(`item-4`)}
                title={'What do I have to do?'}>
                <p>
                  We know you're busy! On average, it takes our clients roughly
                  10 hours per month. All you have to do is film yourself and
                  send us the raw files. This will be discussed on our first
                  call.
                </p>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FAQ;
