import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import HeroBanner from './HeroBanner';
// import Button from 'components/Button';
import { ArrowRightSmall } from 'assets/ICONS';
import Container from 'components/layouts/Container';
import HeroNextButton from './HeroNextButton';
import { useGSAP } from '@gsap/react';
import useObserver from 'hooks/useObserver';
import gsap from 'gsap';
import TextMark from 'components/TextMark';

const Hero = () => {
  const [init, setInit] = useState(false);

  const wrapperRef = useRef();
  const tl = useRef();

  useGSAP(
    () => {
      tl.current = gsap.timeline({ paused: true });

      tl.current
        .fromTo(
          'h1, h3, p, .button--primary',
          { opacity: 0, y: 10 },
          { opacity: 1, y: 0, duration: 0.6, stagger: 0.2 }
        )
        .fromTo(
          '.hero__banner',
          { opacity: 0, x: 10, scale: 1.2 },
          { opacity: 1, x: 0, scale: 1, duration: 0.5 },
          '<=1.35'
        );

      gsap.timeline().fromTo(
        '.hero__bg',
        {
          bottom: '0%',
          x: '50%',
          right: '50%',
          opacity: 1,
        },
        {
          bottom: '50%',
          x: '50%',
          right: '50%',
          opacity: 1,
          rotate: -15,
          duration: 0.8,
          delay: 0.5,
        }
      );

      setInit(true);
    },
    { scope: wrapperRef }
  );
  useObserver(
    {
      element: wrapperRef.current,
      onFocus: () => {
        tl.current.play();
      },
      onBlur: () => {
        tl.current.restart(true).pause();
      },
      options: {},
    },
    [init]
  );

  return (
    <section className="hero" ref={wrapperRef}>
      <Container lines>
        <div className="hero__inner">
          <div className="hero__inner-content">
            <div className="hero__title">
              <div className="hero__title-group">
                <TextMark
                  texts={['Build', 'Scale']}
                  paused
                  timeline={tl.current}
                />
                <h3 className="text-underline color-green">A PROFITABLE</h3>
              </div>
              <h1 className="">Creator Brand.</h1>
            </div>
            <p>
              We work with you to build a creator brand that sells more of your
              &nbsp;
              <span className="text-gradient ">
                {'products, services, or skills '}
              </span>
              &nbsp; organically online.
            </p>
            <NavLink to="/getstarted" className="button button--primary">
                  Get started now {ArrowRightSmall}
                </NavLink>
            {/* <Button>Get started now {ArrowRightSmall} </Button> */}
          </div>
          <HeroBanner />
        </div>
      </Container>
      <div className="hero__bg"></div>
      <HeroNextButton wrapper={wrapperRef} />
    </section>
  );
};

export default Hero;
