export const ArrowRightSmall = (
  <svg
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.75 9.25L5.5 5.5L1.75 1.75"
      stroke="currentColor"
      strokeWidth="2.5"
    />
  </svg>
);
export const ArrowDownSmall = (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1L5.25 4.75L9 1" stroke="currentColor" strokeWidth="2.5" />
  </svg>
);
export const ArrowUp = (
  <svg
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0607 0.939361C12.4749 0.353574 11.5251 0.353574 10.9393 0.939361L1.3934 10.4853C0.807612 11.0711 0.807612 12.0208 1.3934 12.6066C1.97919 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12134L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.939361ZM13.5 5.68121L13.5 2.00002L10.5 2.00002L10.5 5.68121L13.5 5.68121Z"
      fill="currentColor"
    />
  </svg>
);
export const ArrowLeft = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.792893 8.41414C0.402369 8.02361 0.402369 7.39045 0.792893 6.99992L7.15685 0.635964C7.54738 0.24544 8.18054 0.24544 8.57107 0.635964C8.96159 1.02649 8.96159 1.65965 8.57107 2.05018L2.91421 7.70703L8.57107 13.3639C8.96159 13.7544 8.96159 14.3876 8.57107 14.7781C8.18054 15.1686 7.54738 15.1686 7.15685 14.7781L0.792893 8.41414ZM16 8.70703L1.5 8.70703L1.5 6.70703L16 6.70703L16 8.70703Z"
      fill="currentColor"
    />
  </svg>
);
export const ArrowRight = (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2071 8.23299C15.5976 7.84246 15.5976 7.2093 15.2071 6.81877L8.84315 0.454812C8.45262 0.0642872 7.81946 0.0642873 7.42893 0.454812C7.03841 0.845336 7.03841 1.4785 7.42893 1.86903L13.0858 7.52588L7.42893 13.1827C7.03841 13.5733 7.03841 14.2064 7.42893 14.5969C7.81946 14.9875 8.45262 14.9875 8.84315 14.5969L15.2071 8.23299ZM8.74228e-08 8.52588L14.5 8.52588L14.5 6.52588L-8.74228e-08 6.52588L8.74228e-08 8.52588Z"
      fill="currentColor"
    />
  </svg>
);
export const Copyright = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99528 0C3.58668 0 0 3.3645 0 7.5C0 11.6355 3.58668 15 7.99528 15C12.4039 15 15.9906 11.6355 15.9906 7.5C15.9906 3.3645 12.4039 0 7.99528 0ZM7.99528 12.9545C4.78903 12.9545 2.18053 10.5076 2.18053 7.5C2.18053 4.49236 4.78903 2.04545 7.99528 2.04545C11.2015 2.04545 13.81 4.49236 13.81 7.5C13.81 10.5076 11.2015 12.9545 7.99528 12.9545ZM10.1553 8.74527L11.3341 9.77243L10.7401 10.2601C9.99614 10.8707 9.04572 11.2071 8.06412 11.2071C5.85858 11.2071 4.06422 9.55861 4.06422 7.53245C4.06422 5.51093 5.85858 3.86625 8.06412 3.86625C9.04652 3.86625 9.99374 4.19952 10.7311 4.8047L11.3033 5.27448L10.1736 6.33477L9.67204 5.91907C9.24051 5.56159 8.66958 5.36468 8.06412 5.36468C6.73937 5.36468 5.66161 6.33716 5.66161 7.53239C5.66161 8.73239 6.73937 9.70861 8.06412 9.70861C8.66289 9.70861 9.2339 9.50864 9.67204 9.14557L10.1553 8.74527Z"
      fill="currentColor"
    />
  </svg>
);
export const LinkedIn = (
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.0093 0.5H3.377C1.95116 0.5 0.79541 1.63007 0.79541 3.02422V32.9758C0.79541 34.3699 1.95116 35.5 3.377 35.5H34.0093C35.4351 35.5 36.5909 34.3699 36.5909 32.9758V3.02422C36.5909 1.63007 35.4351 0.5 34.0093 0.5V0.5ZM13.4923 26.9551H9.13336V14.1326H13.4923V26.9551ZM11.3129 12.3817H11.2845C9.82183 12.3817 8.87582 11.3972 8.87582 10.1667C8.87582 8.90847 9.85078 7.95117 11.3419 7.95117C12.833 7.95117 13.7506 8.90847 13.779 10.1667C13.779 11.3972 12.833 12.3817 11.3129 12.3817ZM29.2096 26.9551H24.8512V20.0954C24.8512 18.3714 24.2201 17.1957 22.643 17.1957C21.4389 17.1957 20.7217 17.9888 20.4066 18.7544C20.2913 19.0283 20.2632 19.4112 20.2632 19.7944V26.9551H15.9045C15.9045 26.9551 15.9616 15.3356 15.9045 14.1326H20.2632V15.9482C20.8424 15.0744 21.8788 13.8317 24.1914 13.8317C27.0592 13.8317 29.2096 15.6643 29.2096 19.6027V26.9551Z"
      fill="currentColor"
    />
  </svg>
);
