import React from 'react';
import Header from 'components/layouts/Header';
import Footer from 'components/layouts/Footer';
import Hero from './layouts/hero';
import Process from './layouts/process';
import Testimonials from './layouts/testimonials';
import Projects from './layouts/projects';
import FAQ from './layouts/faq';
import Contacts from './layouts/contacts';

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Process />
      <Testimonials />
      <Projects />
      <FAQ />
      <Contacts />
      <Footer />
    </>
  );
};

export default Home;
