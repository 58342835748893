import React, { useRef, useState } from 'react';
import YouTube from 'react-youtube';
import { PlayerState } from 'constant';

const ProjectsVideo = ({ video, addPlayer, onPlay, time }) => {
  const [active, setActive] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);
  const player = useRef(null);

  const createClass = () => {
    let className = 'projects__grid-item';
    className += active ? ' active' : '';
    className += playing ? ' playing' : '';
    className += ready ? ' ready' : '';
    return className;
  };
  const onReady = ({ target }) => {
    setReady(true);
    onPlay(target);
    // window.player = target;
    addPlayer(target);

    player.current = target;
    target.playVideo();
  };
  const updateState = (event) => {
    let isPlaying =
      event.data === PlayerState.PLAYING ||
      event.data === PlayerState.BUFFERING;

    if (isPlaying) onPlay(event.target);
    setPlaying(isPlaying);
    if (!isPlaying) setActive(false);
  };
  const onClick = () => {
    setActive(true);

    if (player.current && player.current.getSize) {
      player.current.playVideo();
    }
  };

  return (
    <div className={createClass()}>
      <div className="projects__grid-item-poster" onClick={onClick}>
        <img
          src={`http://img.youtube.com/vi/${video}/hqdefault.jpg`}
          alt={'poster'}
        />
      </div>
      <div className="ratioBox">
        {(active || ready) && (
          <YouTube
            videoId={video} // defaults -> ''
            // id={string} // defaults -> ''
            className={'projects__grid-item-frame'} // defaults -> ''
            // iframeClassName={string} // defaults -> ''
            // style={object} // defaults -> {}
            // title={string} // defaults -> ''
            // loading={string} // defaults -> undefined
            // opts={obj} // defaults -> {}
            onReady={onReady} // defaults -> noop
            // onPlay={func} // defaults -> noop
            // onPause={onPause} // defaults -> noop
            // onEnd={func} // defaults -> noop
            // onError={func} // defaults -> noop
            onStateChange={updateState} // defaults -> noop
            // onPlaybackRateChange={func} // defaults -> noop
            // onPlaybackQualityChange={func} // defaults -> noop
            opts={{
              playerVars: {
                loop: 1,
                start: time,
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectsVideo;
