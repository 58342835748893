import React, { useRef } from 'react';
import Container from 'components/layouts/Container';
import ProcessItem from './ProcessItem';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Process = () => {
  const wrapper = useRef(null);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    const wrapperEl = wrapper.current;
    let paths = wrapperEl.querySelectorAll('.process__step-line .mask-path');
    let steps = wrapperEl.querySelectorAll('.process__step');
    const percentagePerStep = parseFloat((100 / steps.length).toFixed(2));
    const percentagePerPath = parseFloat((100 / paths.length).toFixed(2));

    steps.forEach((step) => {
      const title = step.querySelector('.process__step-title');
      const text = step.querySelector('.process__step-text');

      // gsap.set([title, text], { opacity: 0 });
      step.setAttribute('data-state', 'hidden');

      step.gsapAnimation = gsap
        .timeline({ paused: true })
        .fromTo(
          title,
          { opacity: 0, x: 30 },
          { opacity: 1, x: 0, duration: 0.2 }
        )
        .fromTo(
          text,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0, duration: 0.2 },
          '>-0.1'
        );
    });
    paths.forEach((path) => {
      let pathLength = path.getTotalLength();
      gsap.set(path, {
        strokeDashoffset: pathLength,
        strokeDasharray: `${pathLength} ${pathLength}`,
      });
    });

    const updateStateOfSteps = (progress) => {
      steps.forEach((step, index) => {
        if (
          progress > index * percentagePerStep &&
          step.getAttribute('data-state') === 'hidden'
        ) {
          step.setAttribute('data-state', 'active');
          step.gsapAnimation.play();
        } else if (
          (progress <= 0 || progress < index * percentagePerStep) &&
          step.getAttribute('data-state') === 'active'
        ) {
          step.setAttribute('data-state', 'hidden');
          step.gsapAnimation.reverse();
        }
      });
    };
    const updateStateOfPaths = (progress) => {
      paths.forEach((path, index) => {
        let pathProgress =
          progress <= index * percentagePerPath
            ? 0
            : progress > (index + 1) * percentagePerPath
            ? percentagePerPath
            : progress - index * percentagePerPath;

        pathProgress = (pathProgress / percentagePerPath) * 100;
        const pathLength = path.getTotalLength();
        const offset = pathLength - (pathLength / 100) * pathProgress;

        gsap.set(path, { strokeDashoffset: offset });
      });
    };
    const animateSteps = (progress) => {
      if (progress > steps.length * percentagePerStep) return;
      updateStateOfSteps(progress);
    };

    ScrollTrigger.create({
      trigger: wrapperEl,
      start: '-20% top', // when the top of the trigger hits the top of the viewport
      end: '60% top', // end after scrolling 500px beyond the start
      // scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      // markers: true,
      onToggle: (self) => {
        // if (self.isActive) {
        //   stepsTl.play();
        // } else {
        //   stepsTl.reverse();
        // }
      },
      onUpdate: (self) => {
        let progress = parseInt(self.progress * 100);
        animateSteps(progress);
        updateStateOfPaths(progress);
      },
    });

    let offsetTop = wrapperEl.offsetTop + wrapperEl.clientHeight;
    if (document.documentElement.scrollTop > offsetTop) {
      steps.forEach((step) => {
        step.gsapAnimation.play();
      });
    }
    // setInit(true);
  });

  return (
    <section id="process" className="process" ref={wrapper}>
      <Container lines>
        <div className="process__inner">
          <h2>How it works...</h2>
          <ul className="process__inner-steps">
            <ProcessItem title={'Cohesive Brand Creation'}>
              If you don’t have brand guidelines in place, we create that for
              you. Things like logos, banners, fonts, colors, & more, all neatly
              wrapped into a style guide. If you already have these brand
              assets, we will translate those into a reusable video assets &
              create consistent video formats on each social platform.
            </ProcessItem>
            <ProcessItem title={'Value Proposition'}>
              Once onboarded, we will speak about how we can position your
              social media channels to best sell your products, services, or
              skills. We will research your specific niche and develop different
              strategies to implement across your various social media
              platforms.
            </ProcessItem>
            <ProcessItem title={'Content Creation/Distribution'} lastItem>
              Done for you video post-production, design, & strategic uploading
              to Socials. This includes ideation, video editing, animating,
              thumbnails, & SEO with titles & descriptions. All you do is:
              script, record, and upload the raw files!
            </ProcessItem>
          </ul>
        </div>
      </Container>
      <div className="bg"></div>
    </section>
  );
};

export default Process;
