import React, { useRef } from 'react';
// import Button from 'components/Button';
import Container from 'components/layouts/Container';
import Footer from 'components/layouts/Footer';
import Header from 'components/layouts/Header';
import { ArrowRightSmall } from 'assets/ICONS';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const Contact = () => {
  return (
    <>
      <Header />
      <ContactInner />
      <Footer />
    </>
  );
};

const ContactInner = () => {
  const wrapperRef = useRef();

  useGSAP(
    () => {
      const tl = gsap.timeline({ paused: true });

      tl.fromTo(
        'h2, h5, p, strong, .button',
        { opacity: 0, y: -10 },
        { opacity: 1, y: 0, duration: 0.25, stagger: 0.2 }
      );
      tl.play();

      // setInit(true);
    },
    { scope: wrapperRef }
  );

  return (
    <section className="contact" ref={wrapperRef}>
      {/* <div className="bg"></div> */}
      <Container lines>
        <div className="contact__inner">
          <div className="contact__title" style={{color:"#000"}}>
            <h5>Get started</h5>
            <h2 style={{fontSize:"3em"}}>
              APPLY <span style={{fontSize:"0.4em",display:"inline-block",verticalAlign:"middle",position:"relative",color:"#4360F6"}}>OR</span> BOOK A CALL NOW
            </h2>
          </div>
          <div className="contact__inner-content" style={{display:"flex",flexDirection:"row",justifyContent:"center",flexWrap:"wrap"}}>
            <div style={{minWidth:"320px",maxWidth:"320px",borderRadius:"25px",gap:"16px",backgroundColor:"white",color:"#fff",display:"flex",flexDirection:"column",justifyContent:"space-between",padding:"24px",border:"1px dashed #4360F6"}}>
                <div style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                    <div style={{color:"#0d1333",width:"100%",fontSize:"16px",flexGrow:1,fontWeight:800,display:"flex",flexDirection:"column",alignItems:"center"}}>Apply</div>
                    <p style={{fontWeight:300,fontSize:"16px"}}>Our approach is highly personalized, and we take pride in understanding your unique challenges. We'll carefully assess your situation to determine if our expertise aligns with your goals.</p>
                </div>
                <div style={{display:"flex",justifyContent:"center"}}><a style={{opacity:0}} target="_blank" rel="noreferrer" href="https://na01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSeHFGPN-dmFvAnKhQBGZszJ9SWAPqo3b2EAB1wmWX77js2aVg%2Fviewform&data=05%7C02%7C%7Cd612bdd1f34145e6e9fa08dc7dd487ae%7C84df9e7fe9f640afb435aaaaaaaaaaaa%7C1%7C0%7C638523598970027388%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=CJ6Whje0%2FwURWhJdADZYl4C1KGOfe6BQgWTN%2BLxKcHc%3D&reserved=0" className="button button--primary">
                  Apply Now {ArrowRightSmall}
                </a> 
                </div>
            </div>
            <div style={{minWidth:"320px",maxWidth:"320px",borderRadius:"25px",gap:"16px",backgroundColor:"white",color:"#fff",display:"flex",flexDirection:"column",justifyContent:"space-between",padding:"24px",border:"1px dashed #4360F6"}}>
                <div style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                    <div style={{color:"#0d1333",width:"100%",fontSize:"16px",flexGrow:1,fontWeight:800,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>Book a Call</div>
                    <p style={{fontWeight:300,fontSize:"16px"}}>Think you’d be a good fit? Feel free to book a free consultation call below.  We look forward to hearing from you!</p>
                </div>
                <div style={{display:"flex",justifyContent:"center"}}><a style={{opacity:0}} target="_blank" rel="noreferrer" href="https://calendly.com/creatorwise/strategypeek" className="button button--primary">
                  Book a Call {ArrowRightSmall}
                </a> 
                </div>
            </div>
            {/* <iframe title="Schedule a call" src="https://calendly.com/creatorwise/strategypeek" width="100%" height="1200px"/> */}
          </div>
          {/* <div className="contact__inner-actions">
          <a href="/getstarted" className="button button--primary">
                  Get Started {ArrowRightSmall}
                </a> 
          </div> */}
        </div>
      </Container>
    </section>
  );
};

export default Contact;
