import React from 'react';

const Button = ({ text, variant = 'primary', ...props }) => {
  return (
    <button
      className={`button ${variant ? 'button--' + variant : ''}`}
      children={text ?? props.children}
      type="button"
      {...props}
    />
  );
};

export default Button;
