import { useGSAP } from '@gsap/react';
import { ArrowUp } from 'assets/ICONS';
import gsap from 'gsap';
import React from 'react';

const Accordion = ({ children }) => {
  return <ul className="accordion">{children}</ul>;
};

const AccordionItem = ({ active, onClick, title, children }) => {
  const ref = React.useRef();
  const tl = React.useRef();

  useGSAP(
    () => {
      let wrapper = ref.current;
      const arrow = wrapper.querySelector('.accordion__item-title-arrow');
      const content = wrapper.querySelector('.accordion__item-content');
      const contentBody = wrapper.querySelector(
        '.accordion__item-content-inner'
      );

      tl.current = gsap.timeline({ paused: true });
      tl.current
        .fromTo(
          content,
          { height: 0 },
          { height: contentBody.clientHeight, duration: 0.5 }
        )
        .fromTo(
          contentBody,
          { opacity: 0, y: 10 },
          { opacity: 1, y: 0, duration: 0.2 },
          '>-0.3'
        )
        .fromTo(
          arrow,
          { rotateX: 0 },
          { rotateX: 180, duration: 0.2 },
          '>-0.3'
        );
    },
    {
      scope: ref.current,
    }
  );
  React.useEffect(() => {
    if (active) tl.current.play();
    else tl.current.reverse();
  }, [active]);

  return (
    <li className={`accordion__item `} ref={ref}>
      <div className="accordion__item-title h6" onClick={onClick}>
        {title}
        <div className="accordion__item-title-arrow">{ArrowUp}</div>
      </div>
      <div className="accordion__item-content">
        <div className="accordion__item-content-inner">{children}</div>
      </div>
    </li>
  );
};

export { Accordion, AccordionItem };
